import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/FirldTrips/2024/Class6/1.jpg';
// import p2 from 'assests/Photos/FirldTrips/2024/Class6/2.jpg';
// import p3 from 'assests/Photos/FirldTrips/2024/Class6/3.jpg';
// import p4 from 'assests/Photos/FirldTrips/2024/Class6/4.jpg';
// import p5 from 'assests/Photos/FirldTrips/2024/Class6/5.jpg';
// import p6 from 'assests/Photos/FirldTrips/2024/Class6/6.jpg';
// import p7 from 'assests/Photos/FirldTrips/2024/Class6/7.jpg';
// import p8 from 'assests/Photos/FirldTrips/2024/Class6/8.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Class10Fieldtrip2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class10Fieldtrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class10Fieldtrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class10Fieldtrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class10Fieldtrip/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    VISIT TO KAADGAL RESORT
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 10   DATE OF EVENT: 01 OCTOBER 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    On 1 October 2024, Class 10 grade students embarked on a memorable field trip to Kaadgal
                                    Resort, nestled on the picturesque Kanakapura Road. The journey itself was marked by vibrant
                                    energy as they all danced and sang heartily turning the bus ride into a joyful prelude to an
                                    exciting day ahead. Upon arriving at Kaadgal Resort, the day began with a delicious breakfast,
                                    which provided the necessary fuel for the adventures awaiting in store. They were divided into
                                    two teams. The first group engaged in an exhilarating rope course challenge, while the second
                                    group focused on their archery skills and enjoyed a spirited game of cricket and football. This
                                    division allowed everyone to choose their preferred activities and make the most of their day.
                                    <br />
                                    At noon, both groups reconvened for a refreshing rain dance session and a relaxing swim. The
                                    playful splashes and groovy tunes turned the pool area into a lively dance floor and the cool
                                    waters offered a welcome respite from the day&#39;s activities. The resort&#39;s serene atmosphere
                                    provided the perfect backdrop for group photos, capturing the smiles and camaraderie of the
                                    day. As memories preserved in photographs, they bid adieu to Kaadgal Resort and embarked
                                    on the return bus ride.
                                    <br />
                                    The field trip to Kaadgal Resort was a resounding success, offering a perfect blend of
                                    adventure, relaxation, and bonding. It was a day filled with laughter, fun, and shared
                                    experiences that would be cherished for years to come.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Good Company on a Journey makes the day seem shorter"
                                <br />
                            </Typography>


                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class10Fieldtrip2024;